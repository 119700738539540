const endpoints = {
  navbar: "profile/navbar.json",
  routes: "profile/routes.json",
  home: "profile/home.json",
  social: "profile/social.json",
  about: "profile/about.json",
  skills: "profile/skills.json",
  education: "profile/education.json",
  experiences: "profile/experiences.json",
  projects: "profile/projects.json",
  blog: "profile/blog.json",
};

export default endpoints;
